<template>
  <div class="loading-div d-flex justify-content-center align-items-center">
    <div></div>
  </div>
</template>

<script>
import { VALIDATE_FORM } from "@/core/services/store/auth.module";

export default {
  methods: {
    initializePlugin(credentials){
      console.log(credentials);
      this.$store.dispatch(VALIDATE_FORM, credentials)
      .then(data => {
        this.$store.dispatch("getColorSettingById", data.colorSettingId)
          .then(() => { 
            if(data.formType == 'life'){
              this.$router.push({ name: 'ProtectionQuoteForm'})
                .then(() => {
                  var pluginMessage = { messageType: "validated" };
                  window.parent.postMessage(JSON.stringify(pluginMessage),"*");
                });
            } else if(data.formType == 'pmi'){
              this.$router.push({ name: 'PMIQuoteForm'})
                .then(() => {
                  var pluginMessage = { messageType: "validated" };
                  window.parent.postMessage(JSON.stringify(pluginMessage),"*");
                });
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
    }
  },
  mounted: function () {
    var _this = this;
    window.addEventListener(
      "message",
      function (res) {
        console.log(res);
        var messageData = {};
        try {
          messageData = JSON.parse(res.data);
        } catch (err) {
          console.log(err);
        }

        if (
          messageData.hasOwnProperty("messageType") &&
          messageData.messageType === "validate"
        ) {
          _this.initializePlugin(messageData.payload);
        }

        if (
          messageData.hasOwnProperty("messageType") &&
          messageData.messageType === "reloadPreview"
        ) {
          _this.$store
            .dispatch("getColorSettingById", messageData.payload.colorSettingId)
            .then(() => {});
        }

      },
      false
    );
  },
};
</script>

<style>
.loading-div {
  height: 100vh;
  width: 100%
}
</style>